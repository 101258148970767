<template>
	<!-- 支付成功 -->
	<div class="page-all">
		<div class="page-message">
			<div class="messageTip">
				<img src="../../../../assets/img/organize/success.png" class="successIcon">
				您已支付成功！
			</div>
			<div class="messageText">订单号：{{data.order_sn}}</div>
			<div class="messageText mr">支付金额：<span style="color: #F83850;">￥{{data.amount}}</span></div>
			<div class="messageText mr">支付方式：{{data.payment}}</div>
			<div class="messageText mr">支付时间：{{data.pay_time}}</div>
			<!-- 按钮 -->
			<div class="returnBtn" @click="toback(e)">返回</div>
			<div class="studyBtn" @click="tostudy()">去学习</div>
			<!-- 查看订单 -->
			<div class="order" @click="tomy(2)">
				查看订单
				<img src="../../../../assets/img/organize/right2.png">
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				data: {}
			};
		},
		created() {
			this.setdata(this.$route.query)
		},
		watch: {
			'$route': function() {
				this.setdata(this.$route.query)
			},
		},
		methods: {
			// 下一步按钮
			setdata(e) {
				if(this.$route.query.out_trade_no){
					this.data.order_sn = this.$route.query.out_trade_no
					this.data.pay_time = this.$route.query.timestamp
					this.data.amount = this.$route.query.total_amount
					this.data.payment = "支付宝"
					return
				}
				this.data = e.data
			},
			toback(e) {
				this.$router.push({
					path: '/home',
					query: {
						id: e
					},
				})
			},
			tomy(e) {
				this.$router.push({
					path: '/my',
					query: {
						id: e
					}
				})
			},
			tostudy(e) {
				this.$router.push({
					path: '/home',
					query: {
						id: e
					}
				})
			},
			toNext() {
				this.$router.push({
					path: '/paySuccess',
					query: {
						id: e
					}
				})
			},
		}
	};
</script>

<style scoped>
	.page-all {
		width: 70%;
		height: auto;
		margin: 0 auto;
		position: relative;
		top: 40px;
		margin-bottom: 52px;
		background-color: white;
		overflow: hidden;
	}

	.page-message {
		width: 360px;
		height: auto;
		margin: 0 auto;
		margin-top: 50px;
		text-align: center;
		margin-bottom: 95px;

	}

	.messageTip {
		width: 190px;
		height: 35px;
		margin: 0 auto;
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #333333;
		line-height: 35px;
	}

	.successIcon {
		float: left;
		height: 31px;
		width: 31px;
	}

	.messageText {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		margin-top: 30px;
	}

	.mr {
		margin-top: 20px;
	}

	.returnBtn {
		display: inline-block;
		width: 140px;
		height: 54px;
		border: 1px solid #00D0B0;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #00D0B0;
		line-height: 54px;
		text-align: center;
		margin-right: 32px;
		margin-top: 40px;
	}

	.studyBtn {
		display: inline-block;
		width: 140px;
		height: 54px;
		background: #00D0B0;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 54px;
		text-align: center;
	}

	.order {
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #00D0B0;
		line-height: 31px;
		position: absolute;
		margin-left: 360px;
		margin-top: -25px;
	}
</style>
